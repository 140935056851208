<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-sm-12 col-md-10 mx-auto">
        <mdb-card cascade>
          <mdb-view
            class="card-header-reversed pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center"
            cascade
          >
            <div
              class="title-card-tec card-header-title color-primary-reversed"
              style="font-weight: 400"
            >
              Tarifs
            </div>
            <mdb-btn
              class="float-right"
              tag="a"
              color="primary"
              icon="plus"
              size="sm"
              small
              @click="addOrEdit(null)"
            >
            </mdb-btn>
          </mdb-view>

          <mdb-card-body class="card-reversed" cascade v-loading="loading">
            <mdb-row>
              <mdb-col sm="12" md="4" class="mb-n4">
                <mdb-select
                  flipOnScroll
                  outline
                  class=""
                  label="Nombre par page"
                  @getValue="nbrPerPageSelected"
                  v-model="dataPagination.pagination.perPageOptions"
                />
              </mdb-col>
              <mdb-col sm="12" md="4" class="mb-n4"> </mdb-col>
              <mdb-col sm="12" md="4" class="mb-n3">
                <mdb-input
                  inputClass="border-reversed "
                  labelClass=" "
                  icon="search"
                  outline
                  type="text"
                  v-model="dataPagination.searchQuery"
                  placeholder="Rechercher"
                  aria-label="Search"
                />
              </mdb-col>
            </mdb-row>

            <mdb-tbl responsiveMd>
              <mdb-tbl-head>
                <tr class="">
                  <th v-for="column in data.columns" :key="column.pack_name">
                    {{ column.label }}
                  </th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body>
                <tr
                  class="tec-table-row "
                  v-for="row in queriedData"
                  :key="row.name"
                >
                  <td class="tec-table-cell text-align-right">
                    {{ row.name }}
                  </td>
                  <td class="tec-table-cell text-align-right">{{ row.frequence }}</td>
                  <td class="tec-table-cell text-align-right">{{ row.amount | formatNumber }}</td>
                  <td class="tec-table-cell text-align-right">{{ row.regime }}</td>
                  <td class="d-flex justify-content-end">
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        style="cursor: pointer; font-weight: 500"
                      >
                        Actions<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                        <el-dropdown-item
                          @click.native="addOrEdit(row)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-edit"
                          >Modifier</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="deleteSub(row.id)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-delete"
                          >Supprimer</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>

            <div class="d-flex justify-content-end">
              <tec-pagination
                v-model="dataPagination.pagination.currentPage"
                :per-page="parseFloat(dataPagination.pagination.perPage)"
                :total="dataPagination.pagination.total"
              ></tec-pagination>
            </div>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>

    <mdb-modal
      centered
      size="md"
      :show="modalSubPack.show"
      @click="modalSubPack.show = false"
            @close="modalSubPack.show = false" 
    >
    <form @submit.prevent="modalSubPack.edit ? updateSubPacks() : saveSubPacks()">
      <mdb-modal-header>
          <mdb-modal-title>{{ modalSubPack.title }}</mdb-modal-title><br>
      </mdb-modal-header>
      <mdb-modal-body class="">
        <fieldset class="color-normal-reversed">
            <legend>Généralité</legend>
            <mdb-row>
                <mdb-col sm="12" md="12" lg='12'>
                    <mdb-input wrapperClass="" inputClass="" labelClass=" color-normal-reversed" 
                    :validation="$store.state.article.errors.ref ? true :false" :invalidFeedback="$store.state.article.errors.ref"
                    v-model="form.pack_name"
                    outline label="Nom du pack" size="lg" ></mdb-input>
                </mdb-col> 
                <mdb-col sm="12" md="4">
                    <mdb-select flipOnScroll wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed "
                    @getValue="getFrequence"
                    :validation="$store.state.article.errors.family ? true :false" :invalidFeedback="$store.state.article.errors.family"
                    v-model="frequenceOption"
                    outline label="Frequence" ></mdb-select>
                </mdb-col>
                <mdb-col sm="12" md="4">
                    <mdb-select flipOnScroll wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed "
                    @getValue="getRegime"
                    :validation="$store.state.article.errors.family ? true :false" :invalidFeedback="$store.state.article.errors.family"
                    v-model="regimeOption"
                    outline label="Regime" ></mdb-select>
                </mdb-col>
                <mdb-col sm="12" md="4">
                    <mdb-input wrapperClass="" inputClass="" labelClass=" color-normal-reversed" 
                    :validation="$store.state.article.errors.puht ? true :false" :invalidFeedback="$store.state.article.errors.puht"
                    v-model="form.amount"
                    type="number" outline label="Montant" ></mdb-input>
                </mdb-col>
                <mdb-col sm="12" md="12" >
                    <label for="">Abonnement par site</label>
                    <mdb-switch  v-model="form.per_site" offLabel="Non" onLabel="Oui"  />
                </mdb-col>
                <mdb-col sm="12" md="12">
                    <mdb-input wrapperClass="" inputClass="" labelClass=" color-normal-reversed" 
                    v-model="form.description"
                    type="textarea"  :rows="2" outline label="Description" ></mdb-input>
                </mdb-col>
            </mdb-row>
        </fieldset>
        <fieldset>
          <legend>Details</legend>
          <div  >
            <mdb-tbl responsiveMd>
              <mdb-tbl-head>
                <tr class="">
                    <th colspan='2'>
                      <form @submit.prevent="addList">
                        <mdb-input v-model="formList.designation"  no-wrapper ></mdb-input>
                      </form>
                    </th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body> 
                <tr v-for="list in form.pack_lists" :key="list.designation">
                  <td style="text-transform:capitalize" >{{list.designation}}</td>
                  <td>
                   <a href="#" @click="removeList(list)"> <mdb-icon icon="trash" color="danger"></mdb-icon> </a>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </div>
        </fieldset>
      </mdb-modal-body>
      <mdb-modal-footer class="">
          <mdb-btn size="md" type="submit" color="primary"  :disabled="modalSubPack.btn">
              <span v-if="!modalSubPack.btn">Sauvegarder</span>
              <span v-if="modalSubPack.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-if="modalSubPack.btn" class="pl-2">Loading...</span>
          </mdb-btn>
          <mdb-btn size="md" outline="primary" @click="modalSubPack.show = false">Fermer</mdb-btn>
      </mdb-modal-footer>
      </form>
    </mdb-modal>
  </div>
</template>

<script>
import TecPagination from "@/tba/components/Pagination";
import {
  mdbIcon,
  mdbSwitch,
  mdbCard,
  mdbCardBody,
  mdbView,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
  mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
} from "mdbvue";
export default {
  metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - ABONNEMENTS",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
  components: {
    mdbIcon,
    mdbSwitch,
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
    TecPagination,
  },

  data() {
    return {
      dataPagination: {
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [
            { text: "5", value: "5", selected: true },
            { text: "10", value: "10" },
            { text: "25", value: "25" },
            { text: "50", value: "50" },
            { text: "100", value: "100" },
          ],
          total: 0,
        },
        loading: false,
        searchQuery: "",
        propsToSearch: [
          "name",
          "start_date",
          "end_date",
          "amount",
          "company",
          "is_finished ",
        ],
      },

      frequenceOption:[
        {
          text:"Annuelle",
          value:"annually"
        },
         {
          text:"Mensuelle",
          value:"monthly"
        },
      ],

      regimeOption:[
        {
          text:"Regime Reel",
          value:"reel"
        },
         {
          text:"Regime TPS",
          value:"tps"
        },
      ],

      data: {
        columns: [
          {
            label: "Titre Tarif",
            field: "pack_name",
            sort: true,
          },
          
          {
            label: "Frequence",
            field: "frequence",
            sort: true,
          },
          {
            label: "Montant",
            field: "amount",
            sort: true,
          },

          {
            label: "Regime",
            field: "regime",
            sort: true,
          },

          {
            label: "",
            field: "action",
            sort: true,
          },
        ],
        rows: [],
      },
      formList:{
        designation:""
      },
      form:{
        id:"",
        pack_name:"",
        frequence:"",
        regime:"",
        amount:"",
        per_site:"",
        description:"",
        pack_lists:[

        ]
      },

      modalSubPack: {
        show: false,
        edit: false,
        title: "Offre Abonnement",
        content: {},
      },
    };
  },

  methods: {
    addOrEdit(data = null) {
      if (data instanceof Object) {
          this.form.pack_name = data.name
          this.form.id = data.id
          this.form.amount = data.amount
          this.form.frequence = data.frequence
          this.form.regime = data.regime
          this.form.description = data.description
          this.form.pack_lists = data.pack_lists
          this.form.per_site = data.per_site

          this.modalSubPack.edit = true;
      } else {
          this.modalSubPack.content = {};
          this.form.pack_name = ""
          this.form.id = ""
          this.form.amount = ""
          this.form.frequence = ""
          this.form.regime = ""
          this.form.description = ""
          this.form.per_site = 0
          this.form.pack_lists = []

          this.modalSubPack.edit = false;
      }

      this.modalSubPack.show = true;
  },


    getFrequence(value){
      this.form.frequence = value
    },

    getRegime(value){
      this.form.regime = value
    },

    async loadSubscriptions() {
      this.$nprogress.start();
      this.loading = !this.loading;
      await this.$store
        .dispatch("subscription_pack/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.data.rows = response.data.subpaks;
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async saveSubPacks() {
      this.$nprogress.start();
      await this.$store
        .dispatch("subscription_pack/save",this.form)
        .then(() => {
          this.$nprogress.done();
          this.loadSubscriptions();
          this.modalSubPack.show = false
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },
    
    async updateSubPacks() {
      this.$nprogress.start();
      await this.$store
        .dispatch("subscription_pack/update",this.form)
        .then(() => {
          this.$nprogress.done();
          this.loadSubscriptions();
          this.modalSubPack.show = false
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async deleteSub(id) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }
      await this.$store
        .dispatch("subscription/delete", id)
        .then((response) => {
          this.$notify({
                 
            message: response.data.message,
            type: "success",
          });
          this.loadSubscriptions();
        })
        .catch((error) => {
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    details(row) {
      this.modalSubPack.content = row;

      this.modalSubPack.title = `${row.company.name}`;

      this.modalSubPack.show = true;
      //
    },

    async addList() {
    
      let obj = {
        designation: this.formList.designation,
      };

      this.form.pack_lists.unshift(obj);
      this.formList.designation = ""
    },

    async removeList(pos) {
      let index = this.form.pack_lists.indexOf(pos);
      this.form.pack_lists.splice(index, 1);
    },

    // Systeme de datatable
    totalCalcul() {
      return (this.dataPagination.pagination.total = this.data.rows.length);
    },

    searchQ() {
      if (!this.dataPagination.searchQuery) {
        // return this.pagedData;
        return new Error("Nothing To Search");
      }

      let result = this.data.rows.filter((row) => {
        let isIncluded = false;

        for (let key of this.dataPagination.propsToSearch) {
          let rowValue = "";
          if (typeof row[key] === "object") {
            switch (key) {
              case "company":
                rowValue = row[key] ? row[key].name.toString().toLowerCase() : "";
                break;
              default:
                break;
            }
          }

          if (typeof row[key] === "string") {
            rowValue = row[key].toString().toLowerCase();
          }
          if (
            rowValue.includes &&
            rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.dataPagination.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(value) {
      let nbr = value;

      return this.nbrPerPage(nbr);
    },

    nbrPerPage(nbr) {
      return (this.dataPagination.pagination.perPage = nbr);
    },
  },

  created() {
    this.loadSubscriptions();
  },

  computed: {
    pageData() {
      return this.data.rows.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    queriedData() {
      let result = this.searchQ();
      return result instanceof Error ? this.pageData : result;
    },

    from() {
      return (
        this.dataPagination.pagination.perPage *
        (this.dataPagination.pagination.currentPage - 1)
      );
    },

    total() {
      return this.totalCalcul();
    },
  },
};
</script>
